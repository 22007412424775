<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :is-view="false"
        :title_modal="title_modal"
        :is-modal="isModal"
      >
        <template #cell(is_periodically)="data">
          <span
            class="badge "
            :class="data.item.is_periodically?'bg-success':'bg-warning'"
          >
            {{ data.item.is_periodically?'Ya':'Tidak' }}
          </span>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import {
  BCard
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      dataurl:"/maintenance-item",
      baseroute:"operasional-maintenance-item",
      title_modal: this.$route.meta.pageTitle,
      isModal:true,
      title:"Daftar Jenis Perawatan",
      fields: [
        { key: 'name', label: 'Name', type: 'input', rules:'required' , sortable: true, placeholder: 'Nama Perawatan' },
        { key: 'type', label: 'Tipe', type: 'select', rules:'required' , sortable: true, options:['Sekali','Time Based','KM Based'] },
        { key: 'interval', label: 'Interval', type: 'number', rules:'required', sortable: true, placeholder: 'Interval (jika Time Base atau KM Base)' },
        { key: 'is_periodically', label: 'Perawatan', lbel: 'Perawatan Berkala' , type: 'bool', sortable: true },
        { key: 'cost', label: 'Biaya Standar', type: 'number', rules:'required', sortable: true, placeholder: 'Biaya Standar' },
        { key: 'description', label: 'Keterangan', type: 'text', rules: 'required', sortable: true, placeholder: 'Keterangan' },
      ],
    }
  },
}
</script>
<style lang="">

</style>
